import request from '@/utils/request';

/**
 * 积分管理 列表
 * @param pram
 */
export function ticketListApi(params) {
  return request({
    url: "/admin/platform/user/assets/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 积分分类的修改
 */
export function operateAssetsApi(params) {
  return request({
    url: "/admin/platform/user/operate/assets",
    method: 'post',
    params: params
  });
}

/**
 * @description 积分详情信息
 */
export function ticketCategoryListApi(params) {
  return request({
    url: "/admin/platform/assets/project/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 积分分类的增加
 */
export function ticketTypeAddApi(params) {
  return request({
    url: "/admin/platform/assets/project/add",
    method: 'post',
    params: params
  });
}

/**
 * @description 积分分类的修改
 */
export function ticketTypeUpdateApi(data) {
  // params = JSON.parse(params);
  return request({
    url: "/admin/platform/user/operate/assets",
    method: 'post',
    data: data
  });
}

/**
 * 资产兑换开关 列表
 * @param pram
 */
export function exchangeListApi(params) {
  return request({
    url: "/admin/platform/assets/exchange/config/list",
    method: 'get',
    params: params
  });
}