var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footers acea-row" },
    _vm._l(_vm.links, function (item) {
      return _c(
        "el-link",
        {
          key: item.key,
          staticClass: "mr15 mb20",
          attrs: { href: item.href, target: "_blank" },
        },
        [_vm._v(_vm._s(item.title))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }